import React from "react";

const ChatGPTDataLearningSection = () => {
  return (
    <section className="relative bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white py-16 px-6 overflow-hidden">
      {/* Animation Wrapper */}
      <div
        className="max-w-5xl mx-auto relative opacity-0 transform translate-y-10 animate-fadeInUp"
      >
        {/* Heading */}
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">
          Do ChatGPT-Type Models Really Learn From Your Data?
        </h2>

        {/* Introductory Statement */}
        <p className="text-lg leading-relaxed text-gray-300 mb-8">
          Despite popular claims, ChatGPT-type models don’t actually “learn”
          from your data the way many businesses might hope. These are
          probabilistic models that rely on patterns in their existing training
          data. If the information you need isn’t already part of the data they
          were trained on, they simply can’t provide it.
        </p>

        {/* How Large Models Work Section */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-[#00d7ff] mb-4">
            How Do Large Models Work?
          </h3>
          <ul className="space-y-4">
            <li>
              <strong>No Real-Time Learning:</strong> These models are
              pre-trained on massive datasets and cannot update or include your
              data unless explicitly retrained. Without retraining, they cannot
              provide answers unique to your business or domain.
            </li>
            <li>
              <strong>Two Ways to Include Your Data:</strong>
              <ul className="pl-6 list-disc mt-2 text-gray-300">
                <li>
                  <strong>Retrain the Model:</strong> Incorporating your data
                  into the training process is resource-intensive and expensive:
                  <ul className="pl-6 list-disc mt-2">
                    <li>ChatGPT costs $700,000 per day to operate.</li>
                    <li>
                      Each API call costs approximately $0.36, making frequent
                      usage unsustainable for low-cost subscription models.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Augmentation with Retrieval (RAG):</strong> Bypasses
                  retraining by pulling information from external databases, but
                  the model doesn’t truly “learn” from your data.
                </li>
              </ul>
            </li>
          </ul>
        </div>

        {/* Cost and Privacy Section */}
        <div>
          <h3 className="text-2xl font-semibold text-[#00d7ff] mb-4">
            Who’s Paying the Cost?
          </h3>
          <ul className="pl-6 list-disc text-gray-300">
            <li>
              <strong>Per-Use Pricing:</strong> API usage is either directly
              charged or reflected in higher subscription costs.
            </li>
            <li>
              <strong>Privacy as Currency:</strong> Many providers monetize user
              data to offset costs, creating privacy risks that can violate
              regulations like HIPAA, GDPR, or PIPEDA.
            </li>
          </ul>
        </div>
      </div>

      {/* Animation Styling */}
      <style jsx>{`
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(50px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fadeInUp {
          animation: fadeInUp 1s ease-out forwards;
        }
      `}</style>
    </section>
  );
};

export default ChatGPTDataLearningSection;
