import React, { useState, useEffect } from 'react';
import logo from '../assets/effie-logo.svg'; 
import logo_white from '../assets/effie-logo-white.svg'; 
import logo_black from '../assets/effie-logo-black.svg'; 
import logo_pink from '../assets/effie-logo3b-new-pink.svg'; 

const Header = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [isMegaMenuOpen, setMegaMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNav = () => {
    setNavOpen((prev) => !prev);
  };

  const toggleMegaMenu = () => {
    setMegaMenuOpen((prev) => !prev);
  };
 
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="border-b text-sm  border-gray-200 dark:bg-neutral-800 dark:border-neutral-700">
      <nav className={`fixed z-50 ibg-transparent  ibg-blue-900 w-screen imax-w-[85rem] iw-full mx-auto flex justify-between items-center py-4 px-4 sm:px-6 lg:px-8
       ${
        isScrolled ? "bg-white ibg-opacity-70  ishadow-md" : "bg-transparent ibg-opacity-30"
      }`}
      onMouseLeave ={() => setMegaMenuOpen(false)}>
        <a className="text-xl font-semibold text-black dark:text-white" href="#" aria-label="Brand">
         <img src={logo_pink} className="h-10"></img>
        </a>

        {/* Mobile Menu Icon */}
        <button
          onClick={toggleNav}
          className="md:hidden bg-fuchsia-500 p-1 border-0 flex items-center text-gray-50 hover:scale-105 focus:outline-none"
          aria-expanded={isNavOpen}
          aria-label="Toggle navigation"
        >
          {isNavOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          )}
        </button>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-4 text-fuchsia-500">
          <a className=" hover:bg-gray-100 px-3 py-2 rounded-lg dark:text-neutral-200" href="#"
          onMouseEnter={() => setMegaMenuOpen(false)}>
            Landing
          </a>

          {/* Mega Menu for Resources */}
          <div className="relative">
            <button
              onClick={toggleMegaMenu}
              onMouseEnter={() => setMegaMenuOpen(true)}
              className="flex items-center text-fuchsia-500 hover:bg-gray-100 px-3 py-2 rounded-lg dark:text-neutral-200"
              aria-haspopup="true"
              aria-expanded={isMegaMenuOpen}
              aria-label="Resources"
            >
              Resources
              <svg
                className={`ml-1 ms-2 size-4 transition-transform ${isMegaMenuOpen ? 'rotate-180' : 'rotate-0'}`}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                >
                <path d="m6 9 6 6 6-6" />
              </svg>
            </button>

            {/* Mega Menu Content */}
            <div className={`fixed z-50 inset-12 rounded-lg flex items-center justify-center min-h-fit  mt-2 bg-white border rounded-md shadow-lg ${isMegaMenuOpen ? 'block' : 'hidden'}`}>
        
              
            <div class="z-50 md:mx-6 lg:mx-8 md:bg-white md:rounded-lg md:shadow-md dark:md:bg-neutral-800">
                    {/* <!-- Grid --> */}
                    <div class="pt-2 md:pt-0 grid grid-cols-1 md:grid-cols-5 gap-3 md:gap-0">
                      <div class="col-span-3 md:p-3">
                        {/* <!-- Grid --> */}
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-1">
                          <div>
                            <div class="flex flex-col">
                              <div class="space-y-0.5">
                                <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">About us</span>

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Support Docs</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="7" height="7" x="14" y="3" rx="1"/><path d="M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Integrations</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"/><polyline points="14 2 14 8 20 8"/><line x1="16" x2="8" y1="13" y2="13"/><line x1="16" x2="8" y1="17" y2="17"/><line x1="10" x2="8" y1="9" y2="9"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Guides</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 11 2-2-2-2"/><path d="M11 13h4"/><rect width="18" height="18" x="3" y="3" rx="2" ry="2"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">API Reference</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 12h-4l-3 9L9 3l-3 9H2"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">API Status</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}
                              </div>
                            </div>
                          </div>
                          {/* <!-- End Col --> */}

                          <div>
                            <div class="flex flex-col">
                              <div class="space-y-0.5">
                                <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">Support</span>

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Help Center</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="4"/><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-4 8"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Developer Hub</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Community Forum</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}
                              </div>

                              <div class="mt-3 md:mt-7 space-y-0.5">
                                <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">Partners</span>

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 7V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2h-6"/><polyline points="14 2 14 8 20 8"/><path d="M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/><path d="M7 16.5 8 22l-3-1-3 1 1-5.5"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Become a Partner</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 5 4 4"/><path d="M13 7 8.7 2.7a2.41 2.41 0 0 0-3.4 0L2.7 5.3a2.41 2.41 0 0 0 0 3.4L7 13"/><path d="m8 6 2-2"/><path d="m2 22 5.5-1.5L21.17 6.83a2.82 2.82 0 0 0-4-4L3.5 16.5Z"/><path d="m18 16 2-2"/><path d="m17 11 4.3 4.3c.94.94.94 2.46 0 3.4l-2.6 2.6c-.94.94-2.46.94-3.4 0L11 17"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Build on Preline</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}
                              </div>
                            </div>
                          </div>
                          {/* <!-- End Col --> */}
                        </div>
                        {/* <!-- End Grid --> */}
                      </div>
                      {/* <!-- End Col --> */}

                      <div class="md:col-span-2">
                        <div class="p-2 md:p-4 md:h-full md:flex-1 flex flex-col md:bg-gray-50 md:rounded-br-lg dark:md:bg-neutral-700">
                          <span class="mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">Customer stories</span>

                          {/* <!-- Link --> */}
                          <a class="group flex flex-row items-center md:items-start md:flex-col gap-4 md:gap-2 focus:outline-none" href="#">
                            <img class="shrink-0 md:w-full size-28 object-cover rounded-lg" src="https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=560&q=80" alt="Customer Story Image" />
                            <div class="grow">
                              <p class="text-sm text-gray-800 dark:text-neutral-200">Preline Projects has proved to be most efficient cloud based project tracking and bug tracking tool.</p>
                              <p class="mt-3 inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium dark:text-blue-400 dark:hover:text-blue-500">
                                Learn more
                                <svg class="shrink-0 size-4 transition ease-in-out group-hover:translate-x-1 group-focus:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
                              </p>
                            </div>
                          </a>
                          {/* <!-- End Link --> */}
                        </div>
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Grid --> */}
                  </div>

              
            </div>
          </div>
          {/* End Mega Menu */}

          <a className=" hover:bg-gray-100 px-3 py-2 rounded-lg dark:text-neutral-200" href="#"
          onMouseEnter={() => setMegaMenuOpen(false)}>
            Account
          </a>
          <a className=" hover:bg-gray-100 px-3 py-2 rounded-lg dark:text-neutral-200" href="#"
          onMouseEnter={() => setMegaMenuOpen(false)}>
            Work
          </a>
          <a className=" hover:bg-gray-100 px-3 py-2 rounded-lg dark:text-neutral-200" href="#"
          onMouseEnter={() => setMegaMenuOpen(false)}>
            Blog
          </a>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isNavOpen && (
        <div className="fixed z-40 w-full md:hidden pt-20 pb-4 im-4 bg-white border-t border-gray-200 dark:bg-neutral-800">
          <a className="block px-4 py-2 text-gray-800 hover:bg-gray-100 dark:text-neutral-200" href="#">
            Landing
          </a>

          {/* Mobile Mega Menu for Resources */}
          <div onClick={toggleMegaMenu}>
            <button className="flex items-center px-4 py-2">
              Resources
              <svg
                className={`ml-1 ms-2 size-4 transition-transform ${isMegaMenuOpen ? 'rotate-180' : 'rotate-0'}`}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                >
                <path d="m6 9 6 6 6-6" />
              </svg>
            </button>

            {/* Mobile Mega Menu Content */}
            {isMegaMenuOpen && (
             
<div class="z-50 md:mx-6 lg:mx-8 md:rounded-lg md:shadow-md bg-indigo-100 px-4 dark:md:bg-neutral-800">
                    {/* <!-- Grid --> */}
                    <div class="pt-2 md:pt-0 grid grid-cols-1 md:grid-cols-5 gap-3 md:gap-0">
                      <div class="col-span-3 md:p-3">
                        {/* <!-- Grid --> */}
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-1">
                          <div>
                            <div class="flex flex-col">
                              <div class="space-y-0.5">
                                <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">About us</span>

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Support Docs</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="7" height="7" x="14" y="3" rx="1"/><path d="M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Integrations</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"/><polyline points="14 2 14 8 20 8"/><line x1="16" x2="8" y1="13" y2="13"/><line x1="16" x2="8" y1="17" y2="17"/><line x1="10" x2="8" y1="9" y2="9"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Guides</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 11 2-2-2-2"/><path d="M11 13h4"/><rect width="18" height="18" x="3" y="3" rx="2" ry="2"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">API Reference</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 12h-4l-3 9L9 3l-3 9H2"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">API Status</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}
                              </div>
                            </div>
                          </div>
                          {/* <!-- End Col --> */}

                          <div>
                            <div class="flex flex-col">
                              <div class="space-y-0.5">
                                <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">Support</span>

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Help Center</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="4"/><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-4 8"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Developer Hub</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Community Forum</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}
                              </div>

                              <div class="mt-3 md:mt-7 space-y-0.5">
                                <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">Partners</span>

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 7V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2h-6"/><polyline points="14 2 14 8 20 8"/><path d="M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/><path d="M7 16.5 8 22l-3-1-3 1 1-5.5"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Become a Partner</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}

                                {/* <!-- Link --> */}
                                <a class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="#">
                                  <svg class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 5 4 4"/><path d="M13 7 8.7 2.7a2.41 2.41 0 0 0-3.4 0L2.7 5.3a2.41 2.41 0 0 0 0 3.4L7 13"/><path d="m8 6 2-2"/><path d="m2 22 5.5-1.5L21.17 6.83a2.82 2.82 0 0 0-4-4L3.5 16.5Z"/><path d="m18 16 2-2"/><path d="m17 11 4.3 4.3c.94.94.94 2.46 0 3.4l-2.6 2.6c-.94.94-2.46.94-3.4 0L11 17"/></svg>
                                  <div class="grow">
                                    <p class="text-sm text-gray-800 dark:text-neutral-200">Build on Preline</p>
                                  </div>
                                </a>
                                {/* <!-- End Link --> */}
                              </div>
                            </div>
                          </div>
                          {/* <!-- End Col --> */}
                        </div>
                        {/* <!-- End Grid --> */}
                      </div>
                      {/* <!-- End Col --> */}

                      <div class="md:col-span-2">
                        <div class="p-2 md:p-4 md:h-full md:flex-1 flex flex-col md:bg-gray-50 md:rounded-br-lg dark:md:bg-neutral-700">
                          <span class="mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">Customer stories</span>

                          {/* <!-- Link --> */}
                          <a class="group flex flex-row items-center md:items-start md:flex-col gap-4 md:gap-2 focus:outline-none" href="#">
                            <img class="shrink-0 md:w-full size-28 object-cover rounded-lg" src="https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=560&q=80" alt="Customer Story Image" />
                            <div class="grow">
                              <p class="text-sm text-gray-800 dark:text-neutral-200">Preline Projects has proved to be most efficient cloud based project tracking and bug tracking tool.</p>
                              <p class="mt-3 inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium dark:text-blue-400 dark:hover:text-blue-500">
                                Learn more
                                <svg class="shrink-0 size-4 transition ease-in-out group-hover:translate-x-1 group-focus:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
                              </p>
                            </div>
                          </a>
                          {/* <!-- End Link --> */}
                        </div>
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Grid --> */}
                  </div>

            )}
          </div>
          {/* End Mobile Mega Menu */}

          <a className="block px-4 py-2 text-gray-800 hover:bg-gray-100 dark:text-neutral-200" href="#">
            Account
          </a>
          <a className="block px-4 py-2 text-gray-800 hover:bg-gray-100 dark:text-neutral-200" href="#">
            Work
          </a>
          <a className="block px-4 py-2 text-gray-800 hover:bg-gray-100 dark:text-neutral-200" href="#">
            Blog
          </a>


          <div className="mt-6 px-4 space-x-4">
          <button className="px-6 py-3 bg-fuchsia-500 bg-[#00d7ff] hover:bg-[#9f32b2] text-white font-semibold  rounded transition duration-300">
            Get Started
          </button>
          <button className="px-6 py-3 bg-transparent border border-fuchsia-500  text-fuchsia-500 hover:bg-[#9f32b2] hover:text-white  border-[#00d7ff] hover:bg-[#00d7ff] hover:text-white text-[#00d7ff] font-semibold rounded transition duration-300">
            Learn More
          </button>
        </div>

        </div>
        

      )}
    </header>
  );
};

export default Header;