import React from "react";
import { FaRobot, FaShieldAlt, FaRocket, FaRegSmile } from "react-icons/fa";

const WhyUseLiveChat = () => {
  const features = [
    {
      icon: <FaRobot size={40} className="text-[#7aa4a9] text-deep-terracotta" />,
      title: "AI-Powered Assistance",
      description: "Leverage intelligent automation to provide instant responses and personalized support.",
    },
    {
      icon: <FaShieldAlt size={40} className="text-[#7aa4a9] text-deep-terracotta" />,
      title: "Secure and Reliable",
      description: "Built with advanced security protocols to ensure your customers' data is always protected.",
    },
    {
      icon: <FaRocket size={40} className="text-[#7aa4a9] text-deep-terracotta" />,
      title: "Boosted Productivity",
      description: "Streamline customer interactions and reduce response times to improve efficiency.",
    },
    {
      icon: <FaRegSmile size={40} className="text-[#7aa4a9] text-deep-terracotta" />,
      title: "Enhanced Customer Experience",
      description: "Delight your customers with seamless and human-like conversations.",
    },
  ];

  return (
    <section className="bg-off-white py-16">
      <div className="container flex flex-col  mx-auto px-6 text-center">
        {/* Section Heading */}
        <h2 className="text-3xl md:text-4xl font-serif font-bold text-charcoal mb-8 animate-fade-in">
          Why Use Our Live Chat?
        </h2>
        <p className="text-lg md:text-xl text-charcoal/80 mb-12 animate-fade-in">
          Discover the features that make our platform the ultimate choice for customer engagement.
        </p>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-all animate-fade-in"
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className="flex justify-center mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-charcoal mb-2">{feature.title}</h3>
              <p className="text-charcoal/80">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUseLiveChat;
