import React from "react";

const IntellectualPropertySection = () => {
  return (
    <section className="relative bg-[#313896] text-white py-16 px-6">
      {/* Diagonal Divider */}
      <div className="absolute inset-0 -skew-y-3 bg-[#9f32b2] z-0"></div>

      {/* Content */}
      <div className="relative z-10 max-w-5xl mx-auto text-center">
        {/* Tagline */}
        <h2 className="text-3xl md:text-4xl font-bold mb-6">
          Protect Your Business's Intellectual Property
        </h2>
        <p className="text-lg md:text-xl font-light text-[#00d7ff]">
          "Every Customer Interaction is an Asset—Guard It Wisely!"
        </p>

        {/* Description */}
        <p className="text-gray-200 mt-6 leading-relaxed max-w-3xl mx-auto">
          When you engage with customers, you're not just providing service; 
          you're creating valuable intellectual property (IP). Each interaction 
          generates insights, feedback, and unique processes that can drive your business forward.
        </p>

        {/* Key Considerations */}
        <div className="mt-8 text-left">
          <h3 className="text-2xl font-semibold mb-4 text-[#00d7ff]">
            Key Considerations:
          </h3>
          <ul className="space-y-4">
            <li className="flex items-start space-x-2">
              <span className="text-[#00d7ff] font-bold">•</span>
              <span>
                <strong>Data Security:</strong> Implement strong security measures to protect customer information.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span className="text-[#00d7ff] font-bold">•</span>
              <span>
                <strong>Ownership Rights:</strong> Ensure your business retains ownership of generated data.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span className="text-[#00d7ff] font-bold">•</span>
              <span>
                <strong>Regulatory Compliance:</strong> Adhere to data protection laws to build customer trust.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span className="text-[#00d7ff] font-bold">•</span>
              <span>
                <strong>Team Training:</strong> Equip staff to recognize and safeguard valuable IP.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <span className="text-[#00d7ff] font-bold">•</span>
              <span>
                <strong>Strategic Management:</strong> Develop a clear IP strategy aligned with your business goals.
              </span>
            </li>
          </ul>
        </div>

        {/* Closing Statement */}
        <p className="mt-8 text-lg font-light text-gray-200">
          By safeguarding your IP, you enhance your competitive edge and foster growth.
        </p>
      </div>
    </section>
  );
};

export default IntellectualPropertySection;
