import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is an AI-enabled live chat platform?",
      answer:
        "An AI-enabled live chat platform leverages artificial intelligence to provide real-time customer support, automate repetitive tasks, and offer personalized interactions.",
    },
    {
      question: "How secure is the platform?",
      answer:
        "Our platform is built with advanced security protocols, including data encryption and compliance with industry standards, to ensure customer data is safe.",
    },
    {
      question: "Can this platform integrate with my existing systems?",
      answer:
        "Yes, our platform is designed to seamlessly integrate with your CRM, ticketing systems, and other business tools.",
    },
    {
      question: "What industries can benefit from this platform?",
      answer:
        "Our live chat platform is versatile and caters to industries like e-commerce, healthcare, finance, and more, enhancing customer engagement across sectors.",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-warm-beige py-16">
      <div className="container flex flex-col mx-auto px-6">
        {/* Section Header */}
        <h2 className="text-3xl md:text-4xl font-serif font-bold text-charcoal text-center mb-8 animate-fade-in">
          Frequently Asked Questions
        </h2>
        <p className="text-lg md:text-xl text-charcoal/80 text-center mb-12 animate-fade-in">
          Have questions? We’ve got answers.
        </p>

        {/* FAQ Items */}
        <div className="space-y-6 w-full">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md transition-all hover:shadow-lg"
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-lg font-semibold text-charcoal">
                  {faq.question}
                </h3>
                {openIndex === index ? (
                  <FaChevronUp className="text-deep-terracotta" />
                ) : (
                  <FaChevronDown className="text-deep-terracotta" />
                )}
              </div>
              {openIndex === index && (
                <p className="mt-4 text-charcoal/80 animate-fade-in">
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
