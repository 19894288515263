



import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { motion } from "framer-motion";
import * as THREE from "three";

import Navbar from "./Navbar10.js";
import Hero from './HeroBlank.js';

import Why from "./Why.js";
import CTA from "./CTA.js";
import FAQ from "./FAQ.js";
import Footer from "./Footer.js";

import Chatbot from "./Chatbot.js";
import IPSection from "./IPSection.js";
import SmartModels from "./SmartModels.js";
import DataLearning from "./DataLearning.js";
import Future from "./Future.js";
import ContactUS from "./ContactUS.jsx";


const BackgroundCanvas = ({ sizeScale = 2.5 }) => {
  return (
    <>
    <div className="relative  iw-full w-screen  h-screen bg-gradient-to-br from-fuchsia-900 via-gray-900 to-gray-800 overflow-hidden">
     <Navbar />
     <div  className="absolute inset-0 bg-gradient-to-br from-black/70 to-transparent
        flex flex-col items-center justify-center text-white text-center"
        style={{
            clipPath: 'polygon(100% 100%, 0% 100% , 0.00% 8.00%, 3.33% 7.80%, 6.67% 7.24%, 10.00% 6.35%, 13.33% 5.24%, 16.67% 4.00%, 20.00% 2.76%, 23.33% 1.65%, 26.67% 0.76%, 30.00% 0.20%, 33.33% 0.00%, 36.67% 0.20%, 40.00% 0.76%, 43.33% 1.65%, 46.67% 2.76%, 50.00% 4.00%, 53.33% 5.24%, 56.67% 6.35%, 60.00% 7.24%, 63.33% 7.80%, 66.67% 8.00%, 70.00% 7.80%, 73.33% 7.24%, 76.67% 6.35%, 80.00% 5.24%, 83.33% 4.00%, 86.67% 2.76%, 90.00% 1.65%, 93.33% 0.76%, 96.67% 0.20%, 100.00% 0.00%)'
           ,  clipPath: 'polygon(100% 100%, 0% 100% , 0.00% 12.56%, 3.33% 11.88%, 6.67% 10.93%, 10.00% 9.79%, 13.33% 8.55%, 16.67% 7.28%, 20.00% 6.10%, 23.33% 5.08%, 26.67% 4.30%, 30.00% 3.82%, 33.33% 3.67%, 36.67% 3.86%, 40.00% 4.38%, 43.33% 5.20%, 46.67% 6.24%, 50.00% 7.44%, 53.33% 8.71%, 56.67% 9.94%, 60.00% 11.06%, 63.33% 11.98%, 66.67% 12.63%, 70.00% 12.96%, 73.33% 12.95%, 76.67% 12.60%, 80.00% 11.94%, 83.33% 11.01%, 86.67% 9.88%, 90.00% 8.64%, 93.33% 7.38%, 96.67% 6.19%, 100.00% 5.15%)'
      
          }}
      > 
      <Hero />

      </div>

      <div className="w-screen  absolute top-1/3 flex-col  mx-auto px-6 irelative z-10 text-center">
      <div>
        <h1 className="text-4xl md:text-6xl font-serif font-bold text-white animate-fade-in-down">
          Revolutionize Customer Engagement
        </h1>
        <p className="mt-4 text-lg md:text-xl text-white animate-fade-in">
          Experience the power of AI-enabled live chat to connect with your customers like never before.
        </p>
      </div>  
      <div className="mt-8 flex justify-center gap-4 animate-fade-in-up">
        <button
          className="bg-indigo-900 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-indigo-900/90 hover:border hover:border-white transition-all"
          onClick={() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" })}
        >
          Get Started
        </button>
        <button
          className="bg-transparent border border-white text-white px-6 py-3 rounded-lg hover:bg-indigo-900 hover:text-white hover:border-0 transition-all"
          onClick={() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" })}
        >
          Learn More
        </button>
      </div>
    </div>
    </div>
 

 <Why />
 <IPSection />
 <CTA />
 <Chatbot />
  
 <Future />
 <SmartModels />
 <DataLearning />
 <FAQ />
 <ContactUS />
 <Footer />
 </>
    
  );
};

const AudioWaves = () => {
  const waves = Array.from({ length: 5 }, (_, i) => i); // Create multiple waves

  return (
    <div className="absolute inset-0 pointer-events-none">
      {waves.map((_, index) => (
        <motion.div
          key={index}
          className="absolute w-full h-full"
          initial={{
            opacity: 0,
            y: Math.random() * 200 - 100, // Random Y offset
            x: Math.random() * 200 - 100, // Random X offset
          }}
          animate={{
            opacity: [0, 0.5, 0],
            y: [0, Math.random() * 50 - 25],
            x: [0, Math.random() * 50 - 25],
          }}
          transition={{
            duration: Math.random() * 3 + 2, // Random animation duration
            repeat: Infinity,
            delay: Math.random() * 2, // Random delay
          }}
        >
          <svg
            className="w-full h-full"
            viewBox="0 0 1440 320"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0,96L48,128C96,160,192,224,288,218.7C384,213,480,139,576,128C672,117,768,171,864,181.3C960,192,1056,160,1152,133.3C1248,107,1344,85,1392,74.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              fill="rgba(56, 189, 248, 0.2)"
            />
          </svg>
        </motion.div>
      ))}
    </div>
  );
};

const MorphingWireframe = ({ position, sizeScale }) => {
  const mesh = useRef();
  const materialRef = useRef();
  const clock = new THREE.Clock();

  // Update the wireframe to morph between random shapes
  useFrame(() => {
    const time = clock.getElapsedTime();
    if (mesh.current) {
      // Randomly morph vertices using noise and time
      mesh.current.geometry.attributes.position.needsUpdate = true;
      const positions = mesh.current.geometry.attributes.position.array;

      for (let i = 0; i < positions.length; i += 3) {
        positions[i] += Math.sin(time + i) * 0.001;
        positions[i + 1] += Math.cos(time + i) * 0.001;
        positions[i + 2] += Math.sin(time - i) * 0.001;
      }
    }
  });

  return (
    <mesh ref={mesh} position={position}>
      {/* Wireframe sphere */}
      <sphereGeometry
        args={[sizeScale * 1.2, 32, 32]} // Dynamically scale sphere size
        attach="geometry"
      />
      <meshStandardMaterial
        ref={materialRef}
        color={`hsl(${Math.random() * 360}, 70%, 50%)`}
        wireframe
        attach="material"
      />
    </mesh>
  );
};


const AnimatedWireframe = ({ position }) => {
    const meshRef = useRef();
    const [geometry, setGeometry] = useState(() => randomGeometry());
    
    const [color, setColor] = useState(() => randomColor());
  
    useFrame(() => {
      if (meshRef.current) {
        meshRef.current.rotation.x += 0.00025; // 0.005   Slow rotation
        meshRef.current.rotation.y += 0.0005;  // 0.01
  
        if (Math.random() < 0.01) {
          // setGeometry(randomGeometry());
          setColor(randomColor());
        }
      }
    });
  
    return (
      <mesh ref={meshRef} position={position}>
        <wireframeGeometry args={[geometry]} />
        <meshBasicMaterial color={color} wireframe />
      </mesh>
    );
  };
  
  const randomGeometry = () => {
    // Randomly generate a geometry type
    const geometries = [
      // new THREE.SphereGeometry(1, 16, 16),
      // new THREE.BoxGeometry(1, 1, 1, 8, 8, 8),
      // new THREE.ConeGeometry(1, 2, 16),
      new THREE.TorusKnotGeometry(1, 0.3, 64, 8),
    ];
    return geometries[Math.floor(Math.random() * geometries.length)];
  };
  
  const randomColor = () => {
    // Generate a random color
    const colors = ["#337aff ", "#38bdf8", "#9333ea", "#facc15", "#22d3ee", "#4ade80"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

export default BackgroundCanvas;
