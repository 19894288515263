// import ima from '../../assets/audio_10.jpeg'; 
import ima2 from '../assets/agent.jpg'; 

const CTASection = () => {
    return (
      <section
        className="relative py-32 bg-fixed bg-cover bg-center text-white"
        style={{ backgroundImage: `url(${ima2})`}}
      >
        <div className="bg-black/80 bg-[#313896]/70 absolute inset-0"></div>
        {/* <img src={img} className="absolute -z-20 inset-0 w-screen overflow-hidden object-cover" /> 
        */}
        <div className="relative container flex-col  mx-auto px-6 text-center">
          <h2 className="text-3xl md:text-5xl font-bold font-serif">
            Ready to Revolutionize Your Customer Experience?
          </h2>
          <p className="mt-4 text-lg text-[#00d7ff]/80">
            Start your free trial today and see the difference intelligent live chat makes!
          </p>
          <div className="mt-6">
            <a
              href="#get-started"
              className="bg-[#9f32b2] px-8 py-4 text-lg font-medium rounded-md hover:bg-[#9f32b2]/90 transition duration-300"
            >
              Get Started for Free
            </a>
          </div>
        </div>
      </section>
    );
  };
  
  export default CTASection;
  
  {/*
     style={{ 
          backgroundImage: 
            "url('https://images.unsplash.com/photo-1593642532871-8b12e02d091c?fit=crop&w=1200&q=80')",
        }} 
    */}