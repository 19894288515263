import React from "react";

const WhySmartModelsSection = () => {
  return (
    <section className="relative bg-gradient-to-b from-[#313896] to-[#9f32b2] text-white py-16 px-6 overflow-hidden">
      {/* Animation Wrapper */}
      <div
        className="max-w-5xl mx-auto relative transform translate-y-20 opacity-0 animate-slideInUp"
      >
        {/* Heading */}
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">
          Why Smart Language Models Outperform Giant Multipurpose Models
        </h2>

        {/* Problem Section */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-[#00d7ff] mb-4">
            The Problem with Giant Models Like ChatGPT
          </h3>
          <ul className="space-y-4">
            <li>
              <strong>Designed for Breadth, Not Depth:</strong> Large models
              like ChatGPT encompass broad knowledge but lack the specificity
              your business needs, providing generic responses.
            </li>
            <li>
              <strong>Impractical for Customization:</strong> Retraining such
              models is prohibitively expensive and time-consuming:
              <ul className="pl-6 list-disc mt-2 text-gray-300">
                <li>Months of training time, even with prepared data.</li>
                <li>Costs reaching hundreds of thousands of dollars.</li>
              </ul>
            </li>
            <li>
              <strong>Fails at Localized Queries:</strong> These models struggle
              with context-specific queries like:
              <ul className="pl-6 list-disc mt-2 text-gray-300">
                <li>“What’s my business address?”</li>
                <li>
                  “Are there parking spots available in front of my location?”
                </li>
              </ul>
            </li>
          </ul>
        </div>

        {/* Smart Language Model Advantage */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-[#00d7ff] mb-4">
            The Smart Language Model Advantage
          </h3>
          <ul className="space-y-4">
            <li>
              <strong>Custom-Built for Your Business Needs:</strong> Tailored
              for specific requirements, unlike generalized giant models.
            </li>
            <li>
              <strong>Efficient and Affordable Training:</strong>
              <ul className="pl-6 list-disc mt-2 text-gray-300">
                <li>Training as short as a week.</li>
                <li>Cost-effective for small- to medium-sized businesses.</li>
              </ul>
            </li>
            <li>
              <strong>Enhanced with RAG (Retrieval-Augmented Generation):</strong>
              <ul className="pl-6 list-disc mt-2 text-gray-300">
                <li>Real-time, accurate, and relevant answers.</li>
                <li>
                  Seamless access to business-specific knowledge and FAQs.
                </li>
              </ul>
            </li>
            <li>
              <strong>Localized Intelligence:</strong> Handles context-specific
              queries, such as:
              <ul className="pl-6 list-disc mt-2 text-gray-300">
                <li>“What’s the status of my order?”</li>
                <li>“Do you offer same-day delivery in my area?”</li>
              </ul>
            </li>
          </ul>
        </div>

        {/* Closing Statement */}
        <p className="text-lg leading-relaxed text-gray-200">
          Integrating smart language models ensures your business:
        </p>
        <ul className="pl-6 list-disc mt-4 text-gray-300">
          <li>Provides accurate, relevant responses that delight customers.</li>
          <li>Saves time and resources by avoiding retraining complexities.</li>
          <li>
            Stays competitive by adopting cutting-edge AI tailored to your
            needs.
          </li>
        </ul>
        <p className="mt-6 text-lg font-light text-gray-200">
          The future of AI in business is smart, efficient, and precise—not
          bulky and generic. Let us help you transition to intelligent systems
          that truly work for you.
        </p>
      </div>

      {/* Animation Styling */}
      <style jsx>{`
        @keyframes slideInUp {
          from {
            transform: translateY(50px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        .animate-slideInUp {
          animation: slideInUp 1s ease-out forwards;
        }
      `}</style>
    </section>
  );
};

export default WhySmartModelsSection;
