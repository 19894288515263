import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

import logo_pink from '../assets/effie-logo3b-new-white.svg'; 

const Footer = () => {
  return (
    <footer className="bg-sky-800 text-white py-12">
      <div className="container flex flex-col  mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Sitemap */}
          <div>
            {/* <h3 className="text-lg font-bold mb-4">Sitemap</h3> */}
            <a href="#" className="text-2xl font-bold h-8">
                     <img className="h-10" src={logo_pink} ></img> {/* AI Live Chat */}
            </a>

            <ul className="space-y-2 mt-6">
              <li><a href="#about" className="hover:text-deep-terracotta">About</a></li>
              <li><a href="#services" className="hover:text-deep-terracotta">Services</a></li>
              <li><a href="#pricing" className="hover:text-deep-terracotta">Pricing</a></li>
              <li><a href="#insights" className="hover:text-deep-terracotta">Insights</a></li>
            </ul>
          </div>

          {/* Generative AI Updates */}
          <div>
            <h3 className="text-lg font-bold mb-4">Stay Updated</h3>
            <p className="mb-4 text-sm">
              Would you like to stay informed on recent developments in Generative AI?
            </p>
            <form className="flex flex-col space-y-4">
              <input
                type="email"
                placeholder="Your email address"
                className="p-3 rounded bg-white text-sky-800 focus:ring-2 focus:ring-deep-terracotta"
              />
              <button
                type="submit"
                className="bg-deep-terracotta text-white py-2 px-4 rounded hover:bg-deep-terracotta/90 transition-all"
              >
                Subscribe
              </button>
            </form>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-lg font-bold mb-4">Connect with Us</h3>
            <div className="flex space-x-4">
              <a
                href="#"
                className="text-white hover:text-deep-terracotta transition-all"
              >
                <FaFacebook size={24} />
              </a>
              <a
                href="#"
                className="text-white hover:text-deep-terracotta transition-all"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="#"
                className="text-white hover:text-deep-terracotta transition-all"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href="#"
                className="text-white hover:text-deep-terracotta transition-all"
              >
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-12 text-center text-sm border-t border-white/20 pt-6">
          © {new Date().getFullYear()} DataSpeckle Scientific. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
