import React from 'react';

const ChatbotFutureSection = () => {
    return (
        <section className="bg-gray-50 py-12">
            <div className="container flex-col  mx-auto px-4">
                <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
                    The Future of Chatbots: A New Era
                </h1>
                <h2 className="text-xl font-semibold text-gray-700 mb-4 text-center">
                    The Truth Behind Outdated Technology
                </h2>
                <p className="text-gray-600 mb-8 itext-center">
                    Many companies continue to promote old technology, making false claims about their capabilities. They disguise their outdated systems as "AI Agents" or "AI-enabled bots," leading customers to believe they are leveraging advanced AI when, in fact, they are not.
                </p>

                <h2 className="text-xl font-semibold text-gray-700 mb-4 itext-center">
                    Why Settle for Less?
                </h2>
                <p>These companies face two options: either developing their own algorithms or outsourcing their needs to external providers.

                </p>
                <ul className="list-disc list-inside text-gray-600 mb-8 px-4">
                    <li>Outsourcing to expensive providers like ChatGPT can compromise your intellectual property and privacy.</li>
                    <li>Attempting to develop their own Large Language Models (LLMs) is often a costly and complex endeavor beyond their capabilities.</li>
                </ul>

                <h2 className="text-xl font-semibold text-gray-700 mb-4 itext-center">
                    The Illusion of AI
                </h2>
                <p className="text-gray-600  itext-center">
                    Some companies even claim they can train algorithms akin to those used by ChatGPT with your data. The reality is stark:
                </p>
                <ul className="list-disc list-inside text-gray-600 mb-8 px-4">
                    <li>Training LLMs at the size of ChatGPT costs millions and requires extensive time—up to 180 days—far exceeding what minimal subscription fees can cover.</li>
                    <li>The infrastructure and expertise needed for such projects are often underestimated, leading to unrealistic promises.</li>
                </ul>

                <h2 className="text-xl font-semibold text-gray-700 mb-4 itext-center">
                    The Transition Period
                </h2>
                <p className="text-gray-600 mb-8 itext-center">
                    Why do companies make these impossible offers? Out of fear of obsolescence, they leverage customer ignorance and use vague terminology to create an illusion of innovation.
                </p>

                <h2 className="text-xl font-semibold text-gray-700 mb-4 itext-center">
                    A Paradigm Shift
                </h2>
                <p className="text-gray-600 mb-8 itext-center">
                    Just as we adapted to cars, the internet, and smartphones, the confusion surrounding AI will eventually dissipate. As these technologies mature, they will become essential tools that streamline processes and enhance our everyday experiences.
                </p>

                <h2 className="text-xl font-semibold text-gray-700 mb-4 itext-center">
                    Join Us on the Journey
                </h2>
                <p className="text-gray-600 mb-8 itext-center">
                    Stay informed and be part of the transition. Embrace the future of chatbot technology that truly understands and evolves with you.
                </p>
                <div className="flex justify-center">
                    <a 
                        href="/get-started" 
                        className="bg-deep-terracotta text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-sky-600 transition duration-300"
                    >
                        Get Started
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ChatbotFutureSection;