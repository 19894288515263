import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const HeroSection = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    // Scene, Camera, and Renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 10;

    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Create Sine Wave Geometry
    const createSineWave = (amplitude, frequency, offset, color) => {
      const waveMaterial = new THREE.LineBasicMaterial({ color });
      const waveGeometry = new THREE.BufferGeometry();
      const points = [];

      for (let x = -50; x <= 50; x += 0.1) {
        points.push(new THREE.Vector3(x, Math.sin(x * frequency + offset) * amplitude, 0));
      }

      waveGeometry.setFromPoints(points);
      return new THREE.Line(waveGeometry, waveMaterial);
    };

    // Add Multiple Sine Waves
    const waves = [];
    const waveColors = [0xff36e0, 0xff36e0, 0xff36e0];
    // [0x006600, 0x00aa00, 0x88cc00];
    for (let i = 0; i < 3; i++) {
      const amplitude = 0.5 + i * 0.2;
      const frequency = 0.2 + i * 0.05;
      const offset = i * Math.PI / 4;
      const wave = createSineWave(amplitude, frequency, offset, waveColors[i]);
      wave.position.y = -1 + i * 0.5;
      waves.push({ wave, frequency, amplitude, offset });
      scene.add(wave);
    }

    // Animation Loop
    const clock = new THREE.Clock();
    const animate = () => {
      const elapsedTime = clock.getElapsedTime();

      // Update each wave's position
      waves.forEach(({ wave, frequency, amplitude, offset }) => {
        const positions = wave.geometry.attributes.position.array;
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i];
          positions[i + 1] = Math.sin(x * frequency + elapsedTime + offset) * amplitude;
        }
        wave.geometry.attributes.position.needsUpdate = true;
      });

      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    // Handle Resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      renderer.dispose();
    };
  }, []);

  return (
    <div className="relative w-full  h-screen overflow-hidden bg-gradient-to-b from-sky-950 via-violet-900 to-sky-600 ">
      <canvas ref={canvasRef} className="absolute inset-0 mt-52" />
    </div>
  );
};

export default HeroSection;
