import React from "react";

const AIInfusedChatbotSection = () => {
  return (
    <section className="relative overflow-hidden bg-sky-800 ibg-[#313896] text-white">
      {/* Diagonal Divider */}
      <div className="absolute top-0 left-0 w-full h-full z-0">
        <svg
          className="w-full h-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          fill="#9f32b2"
        >
          <polygon points="0,0 100,0 100,100" />
        </svg>
      </div>

      <div
        className="relative z-10 container flex-col  mx-auto px-6 py-16 md:py-24"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {/* Title */}
        <h2 className="text-3xl md:text-4xl font-bold mb-8">
          AI-Infused Chatbot
        </h2>

        {/* Subtitle */}
        <p className="text-[#00d7ff] text-lg mb-6 font-semibold">
          With LLM, RAG, and Self-Correction Capabilities
        </p>

        {/* Definition */}
        <p className="text-gray-300 leading-relaxed mb-8">
          A chatbot enhanced by artificial intelligence capabilities,
          particularly large language models (LLMs), retrieval-augmented
          generation (RAG), and self-correction mechanisms. It provides
          accurate, contextually relevant, and dynamic responses by integrating
          real-time information retrieval with conversational AI and
          incorporates mechanisms to identify, address, and learn from
          hallucinations (erroneous or fabricated responses).
        </p>

        {/* Key Features */}
        <h3 className="text-2xl text-[#00d7ff] font-bold mb-4">Key Features:</h3>
        <ul className="list-disc pl-6 space-y-3">
          <li>
            <span className="text-white font-semibold">Task-Specific:</span>{" "}
            Handles predefined tasks such as customer support, knowledge
            sharing, or scheduling but with advanced contextual awareness.
          </li>
          <li>
            <span className="text-white font-semibold">LLM-Powered NLP:</span>{" "}
            Leverages sophisticated language understanding and generation
            capabilities to simulate human-like conversations.
          </li>
          <li>
            <span className="text-white font-semibold">
              Retrieval-Augmented Generation (RAG):
            </span>{" "}
            Integrates a knowledge retrieval system to fetch accurate,
            real-time, and domain-specific information, ensuring high relevance
            and factual correctness.
          </li>
          <li>
            <span className="text-white font-semibold">
              Hallucination Awareness:
            </span>{" "}
            Recognizes when it may be providing incorrect or fabricated
            information and transparently notifies the user of potential
            inaccuracies.
          </li>
          <li>
            <span className="text-white font-semibold">Expert Escalation:</span>{" "}
            Automatically escalates complex or uncertain queries to human
            experts, waits for their input, and relays the verified response
            back to the user.
          </li>
        </ul>

        {/* Continuous Improvement */}
        <h3 className="text-2xl text-[#00d7ff] font-bold mt-8 mb-4">
          Continuous Improvement:
        </h3>
        <ul className="list-disc pl-6 space-y-3">
          <li>
            Updates its knowledge base dynamically with expert feedback.
          </li>
          <li>
            Periodically incorporates collected interactions and validated data
            into its training pipeline, refining its capabilities over time.
          </li>
          <li>
            Combines LLM and RAG to tailor responses dynamically while improving
            accuracy through user feedback loops.
          </li>
        </ul>

        {/* Workflow for Hallucination Handling */}
        <h3 className="text-2xl text-[#00d7ff] font-bold mt-8 mb-4">
          Workflow for Hallucination Handling:
        </h3>
        <ul className="list-disc pl-6 space-y-3">
          <li>
            <span className="font-semibold">Detection:</span> Uses pre-trained
            models to flag when a response may be uncertain or potentially
            fabricated.
          </li>
          <li>
            <span className="font-semibold">Notification:</span> Alerts the
            user with a disclaimer, e.g., “I’m unsure if my answer is fully
            accurate. Let me confirm with an expert.”
          </li>
          <li>
            <span className="font-semibold">Expert Query:</span> Routes the
            query to an available expert, gathers their input, and provides the
            user with a corrected response.
          </li>
          <li>
            <span className="font-semibold">Knowledge Update:</span> Stores the
            verified answer in the knowledge base and incorporates it into
            future updates of the chatbot's training model.
          </li>
          <li>
            <span className="font-semibold">Periodic Model Update:</span>{" "}
            Compiles sufficient validated data over time to retrain the
            underlying model, enhancing accuracy and reducing future
            hallucinations.
          </li>
        </ul>

        {/* Examples */}
        <h3 className="text-2xl text-[#00d7ff] font-bold mt-8 mb-4">Examples:</h3>
        <p className="text-gray-300 leading-relaxed">
          <span className="font-semibold">Healthcare:</span> A healthcare
          chatbot that detects uncertainty in its response to a medical query,
          flags the response, and escalates the question to a certified
          professional.
        </p>
        <p className="text-gray-300 leading-relaxed mt-4">
          <span className="font-semibold">Technical Support:</span> A technical
          support bot that integrates expert-provided solutions into its
          database, ensuring users consistently receive accurate,
          expert-validated advice.
        </p>
      </div>
    </section>
  );
};

export default AIInfusedChatbotSection;
