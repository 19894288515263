// import React, { useState } from "react";
// import emailjs from "emailjs-com";
// import Illustration4 from "../assets/new-illustration-3-cropped.png";

// export default function ContactUS() {
//   const [fullName, setFullName] = useState("");
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Form validation
//     if (!fullName || !email || !message) {
//       alert("Please fill in all fields");
//       return;
//     }

//     // Configure EmailJS with your service ID, template ID, and user ID
//     emailjs.init("service_abeygkm");

//     try {
//       const templateParams = {
//         fullName,
//         email,
//         message,
//       };

//       // Send the email using the configured EmailJS service and template
//       //   await emailjs.send(
//       //     "service_abeygkm",
//       //     "template_f9m7xhh",
//       //     templateParams,
//       //     "RSbwVm6yGHKIJFHUD"
//       //   );

//       alert("Email sent successfully");
//       setFullName("");
//       setEmail("");
//       setMessage("");
//     } catch (error) {
//       console.error("Failed to send email:", error);
//       alert("Failed to send email. Please try again later.");
//     }
//   };

//   return (
//     <div id="contact">
//       <div className="hidden md:block text-gray-100 px-13 md:px-5 py-10 border-t border-gray-200 md:mb-5">
//         <div className="text-center"></div>
//         <div className="max-w-screen-xl font-nunito-sans mt-19 px-8 grid gap-0 grid-cols-1 md:grid-cols-2 md:px-0 lg:px-0 xl:px-0 py-16 mx-auto bg-sc1Shade6  text-gray-900 rounded-lg shadow-lg">
//           <div className="flex flex-col justify-between">
//             <div>
//               <h2 className="uppercase text-3xl lg:text-3xl text-sc1Shade4 text-center font-nunito-sans font-bold leading-tight">
//                 Send Us a Message
//               </h2>
//               <div className="mt-3 text-base text-center">
//                 Know More about AI and Machine Learning in your domain.
//               </div>
//             </div>

//             <div className="flex justify-center items-center">
//               <img
//                 src={Illustration4}
//                 width={200}
//                 alt="contact us"
//                 className="hidden md:block inline w-30 mt-4"
//               />
//               <img
//                 src={Illustration4}
//                 width={200}
//                 alt="contact us"
//                 className="md:hidden inline w-60 mt-4 mb-4"
//               />
//             </div>
//           </div>

//           <div className="md:mr-10">
//   <form onSubmit={handleSubmit}>
//     <div>
//       <input
//         className="w-full bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//         type="text"
//         placeholder=" Full Name"
//         value={fullName} // Changed from defaultValue to value
//         onChange={(e) => setFullName(e.target.value)}
//       />
//     </div>

//     <div className="mt-2">
//       <input
//         className="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//         type="email"
//         placeholder="Email"
//         value={email} // Changed from defaultValue to value
//         onChange={(e) => setEmail(e.target.value)}
//       />
//     </div>

//     <div className="mt-2">
//       <textarea
//         className="w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//         value={message} // Changed from defaultValue to value
//         placeholder="Type yor message here . . ."
//         onChange={(e) => setMessage(e.target.value)}
//       ></textarea>
//     </div>

//     <div className="mt-4">
//       <button
//         type="submit"
//         className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
//       >
//         Send Message
//       </button>
//     </div>
//   </form>
// </div>
//         </div>
//       </div>

//       <div className="md:hidden mt-5 text-gray-100 px-13 md:px-5 py-0 border-t border-gray-200 md:mb-5">
//         <div className="text-center"></div>
//         <div className="max-w-screen-xl font-nunito-sans mt-19 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-0 lg:px-0 xl:px-0 py-16 mx-auto bg-sc1Shade6  text-gray-900 rounded-lg shadow-lg">
//           <div className="flex flex-col justify-between">
//             <div>
//               <h2 className="uppercase text-3xl lg:text-3xl text-sc1Shade4 text-center font-nunito-sans font-bold leading-tight">
//                 Send Us a Message
//               </h2>
//               <div className="mt-3 text-base text-center">
//                 Know More about AI and Machine Learning in your domain.
//               </div>
//             </div>

//             <div className="flex justify-center items-center">
//               <img
//                 src={Illustration4}
//                 width={200}
//                 alt="contact us"
//                 className="hidden md:block inline w-90 mt-4"
//               />
//               <img
//                 src={Illustration4}
//                 width={200}
//                 alt="contact us"
//                 className="md:hidden inline w-60 mt-4 mb-4"
//               />
//             </div>
//           </div>

//           <div className="md:mr-5">
//             <form onSubmit={handleSubmit}>
//               <div>
//                 <input
//                   className="w-full bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   type="text"
//                   placeholder=" Full Name"
//                   defaultValue={fullName}
//                   onChange={(e) => setFullName(e.target.value)}
//                 />
//               </div>

//               <div className="mt-2">
//                 <input
//                   className="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   type="email"
//                   placeholder="Email"
//                   defaultValue={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//               </div>

//               <div className="mt-2">
//                 <textarea
//                   className="w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
//                   defaultValue={message}
//                   placeholder="Type yor message here . . ."
//                   onChange={(e) => setMessage(e.target.value)}
//                 ></textarea>
//               </div>

//               <div className="mt-4">
//                 <button
//                   type="submit"
//                   className="uppercase text-base font-bold tracking-wide bg-[#9f32b2] hover:bg-[#9f32b2]/90 transition duration-300 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
//                 >
//                   Send Message
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState } from "react";
import Illustration4 from "../assets/new-illustration-3-cropped.png";

export default function ContactUS() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    if (!fullName || !email || !message) {
      alert("Please fill in all fields");
      return;
    }

    // Show success alert and clear fields
    alert("Email sent successfully");
    setFullName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div id="contact">
      <div className="hidden md:block text-gray-100 px-13 md:px-5 py-10 border-t border-gray-200 md:mb-5">
        <div className="text-center"></div>
        <div className="max-w-screen-xl font-nunito-sans mt-19 px-8 grid gap-0 grid-cols-1 md:grid-cols-2 md:px-0 lg:px-0 xl:px-0 py-16 mx-auto bg-sc1Shade6 text-gray-900 rounded-lg shadow-lg">
          <div className="flex flex-col justify-between">
            <div>
              <h2 className="uppercase text-3xl lg:text-3xl text-sc1Shade4 text-center font-nunito-sans font-bold leading-tight">
                Send Us a Message
              </h2>
              <div className="mt-3 text-base text-center">
                Know More about AI and Machine Learning in your domain.
              </div>
            </div>

            <div className="flex justify-center items-center">
              <img
                src={Illustration4}
                width={200}
                alt="contact us"
                className="hidden md:block inline w-30 mt-4"
              />
              <img
                src={Illustration4}
                width={200}
                alt="contact us"
                className="md:hidden inline w-60 mt-4 mb-4"
              />
            </div>
          </div>

          <div className="md:mr-10">
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  className="w-full bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder=" Full Name"
                  value={fullName} // Changed to controlled input
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="mt-2">
                <input
                  className="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder="Email"
                  value={email} // Changed to controlled input
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-2">
                <textarea
                  className="w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  value={message} // Changed to controlled input
                  placeholder="Type yor message here . . ."
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  className="uppercase text-base font-bold tracking-wide bg-[#9f32b2] hover:bg-[#9f32b2]/90 transition duration-300 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="md:hidden mt-5 text-gray-100 px-13 md:px-5 py-0 border-t border-gray-200 md:mb-5">
        <div className="text-center"></div>
        <div className="max-w-screen-xl font-nunito-sans mt-19 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-0 lg:px-0 xl:px-0 py-16 mx-auto bg-sc1Shade6 text-gray-900 rounded-lg shadow-lg">
          <div className="flex flex-col justify-between">
            <div>
              <h2 className="uppercase text-3xl lg:text-3xl text-sc1Shade4 text-center font-nunito-sans font-bold leading-tight">
                Send Us a Message
              </h2>
              <div className="mt-3 text-base text-center">
                Know More about AI and Machine Learning in your domain.
              </div>
            </div>

            <div className="flex justify-center items-center">
              <img
                src={Illustration4}
                width={200}
                alt="contact us"
                className="hidden md:block inline w-90 mt-4"
              />
              <img
                src={Illustration4}
                width={200}
                alt="contact us"
                className="md:hidden inline w-60 mt-4 mb-4"
              />
            </div>
          </div>

          <div className="md:mr-5">
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  className="w-full bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder=" Full Name"
                  value={fullName} // Changed to controlled input
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="mt-2">
                <input
                  className="w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="email"
                  placeholder="Email"
                  value={email} // Changed to controlled input
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-2">
                <textarea
                  className="w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  value={message} // Changed to controlled input
                  placeholder="Type yor message here . . ."
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  className="uppercase text-base font-bold tracking-wide bg-[#9f32b2] hover:bg-[#9f32b2]/90 transition duration-300 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}